<div class="contenedor" itemscope itemtype="https://schema.org/LocalBusiness">
  <h1><br><span itemprop="name">GEOMARES INGENIERÍA Y EXPLORACIÓN GEOFÍSICA</span></h1>
  <img src="assets/ico/whatsapp.png">
      <br>

    <span itemprop="telephone">  <p>222 429 7504
      <br>
      222 212 0465</p></span>
  <img src="assets/ico/c-e.png" alt="mail">
<span itemprop="email">
  <p>
    geomaresgeofisica
    <br>
    @gmail.com
  </p>

</span>
    <img src="assets/ico/facebook.png">
      <p>  <br>
        <a href="https://www.facebook.com/GeomaresIngenieria" itemprop="url">facebook.com/GeomaresIngenieria</a>
        <br></p>
    <img src="assets/ico/mapa.png">
      <br>
      <div itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
        <span itemprop="streetAddress">
        <p>Calle Antonio Pérez Rivera
          <br>
          #149, Col. El mirador
          <br>
          C.P. 91170
        </p>
        </span>
        <span itemprop="addressLocality"><p>Veracruz Mexico</p></span>,
        <span itemprop="addressRegion"><p>Xalapa-Enríquez</p></span>
      </div>

</div>

