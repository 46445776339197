  <div class="div-head">
    <img src="assets/img/ondas.png" alt="back">
    <h1 class="metodo">Métodos</h1>
  </div>

<section>

  <div class="metodos">
    <button mat-button>Sondeos Eléctricos Verticales (SEV)</button>
    <button mat-button >Tomografía Eléctrica</button>
    <button mat-button>Polarización Inducida</button>
    <button mat-button>Calicatas Eléctricas</button>
    <button mat-button>Potencial Espontáneo</button>
    <button mat-button>Sísmica de Refracción</button>
    <button mat-button>Radar de Penetración Terrestre, GPR</button>
  </div>
</section>



