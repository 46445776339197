
<div itemscope itemtype="https://schema.org/LocalBusiness">
  <img itemprop="logo" src="assets/logo.png" alt="logo">
</div>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Servicios" focus> <app-servicios></app-servicios> </mat-tab>
      <mat-tab label="Métodos"> <app-metodos></app-metodos> </mat-tab>
      <mat-tab label="Acerca de nosotros"> <app-info></app-info> </mat-tab>
      <mat-tab label="Contacto"><app-contacto></app-contacto></mat-tab>
    </mat-tab-group>

