
<div>
  <div class="uno">
    <img src="assets/img/tierra.png" alt="roca">
  </div>

  <div class="dos" itemscope itemtype="https://schema.org/LocalBusiness">
    <h1 itemprop="potentialAction">
      Exploración Geofísica
    </h1>
    <p itemprop="potentialAction">
      Los métodos de exploración geofísica nos proporcionan una visión más detallada de las propiedades
      físicas y litológicas del subsuelo, lo que contribuye a una mejor ejecución de los trabajos,
      es por esto que actualmente las técnicas geofísicas cumplen un papel muy importante en las áreas
      de la ingeniería civil, minería,geotecnia ,exploración de aguas subterráneas y
      así como en el sector energético.
    </p>
  </div>



  <div class="metodos" itemscope itemtype="https://schema.org/Service">
    <button mat-button itemprop="serviceType">Detección de cuerpos de agua subterráneos</button>
    <button mat-button itemprop="serviceType">Caracterización litológica para cimentaciones</button>
    <button mat-button itemprop="serviceType">Estudios geohidrológicos para elaboración de pozos de agua</button>
    <button mat-button itemprop="serviceType">Localización de cavidades subterráneas y/o fracturas</button>
    <button mat-button itemprop="serviceType">Exploración y caracterización de recursos minerales</button>
    <button mat-button itemprop="serviceType">Mapeo de fracturas y fallas geológicas</button>
    <button mat-button itemprop="serviceType">Determinación de peligro y riesgo sísmico</button>
    <button mat-button itemprop="serviceType">Mapeo y monitoreo de plumas contaminantes</button>
    <button mat-button itemprop="serviceType">Caracterización estratigráfica del terreno</button>
    <button mat-button itemprop="serviceType">Cálculo de módulos elásticos del subsuelo
      <br>
      a partir de las velocidades de onda   (P y S)</button>
    <button mat-button itemprop="serviceType">Caracterización del basamento rocoso</button>
    <button mat-button itemprop="serviceType">Evaluación de bancos de material (arena, grava, roca, etc)</button>
    <button mat-button itemprop="serviceType">Vibración ambiental o monitoreo de microtemores</button>
    <button mat-button itemprop="serviceType">Tendidos de refracción sísmica</button>
    <button mat-button itemprop="serviceType">Detección de huecos y fracturas bajo el pavimento</button>
    <button mat-button itemprop="serviceType">Investigaciones Forenses, fosas clandestinas</button>
    <button mat-button itemprop="serviceType">Arqueología y mapeo de estructuras</button>
    <button mat-button itemprop="serviceType">Pararrayos</button>
  </div>

  <div itemscope itemtype="https://schema.org/Service">
    <h1 itemprop="serviceType">Ingeniería y construcción</h1>
    <br>
      <img src="assets/img/construccion.png" alt="construccion">
    <br>
      <button mat-stroked-button itemprop="serviceType">Construcción y Diseño de Cimentaciones</button>

    <br>
      <img src="assets/img/construccion2.png" alt="pilotes">
    <br>
      <button mat-stroked-button itemprop="serviceType">Pilotes para Cimentación</button>
    <br>
      <img src="assets/img/pilotes.png" alt="construccion">
    <br>
      <button mat-stroked-button itemprop="serviceType">Obra Civil</button>
    <br>
  </div>

  <h1 itemprop="serviceType">Arrendamiento de equipo</h1>
  <div class="renta" itemscope itemtype="https://schema.org/Service">
    <img src="assets/img/renta2.png" alt="construccion">
    <br>
    <button mat-stroked-button itemprop="serviceType">Resistivímetro</button>
  </div>

</div>
