<img src="assets/img/suelo.png" alt="suelo">

<div>
  <div>
    <h1>¿Quiénes somos?</h1>
  </div>
  <div>
<p>
  GEOMARES es una empresa
  enfocada a ofrecer calidad y eficacia
  en la ejecución de todo tipo de obras
  y proyectos que el cliente requiera,
  procurando siempre un ambiente
  de trabajo cordial y confiable.
  <br>
  <br>
        La satisfacción de nuestros clientes
  es una de nuestras principales metas,
  y la garantizamos proporcionando
  un servicio efectivo, siempre
  a la vanguardia.
</p>
  </div>
  <div>
    <h1>Misión/Visión</h1>
  </div>
  <div>
    <p>
      En GEOMARES contamos con el
    conocimiento de diferentes métodos
    y tecnologías especializadas en
    ciencias de la tierra cuya aplicación
    nos da la ventaja de poder identificar
    riesgos potenciales con antelación,
    y asi optimizar el desarrollo
    de alternativas para resolver problemas
    complejos presentes en las diversas
    áreas de la ingeniería.
    <br>
    <br>
    Nuestra visión es posicionarnos
  como una empresa sobresaliente
  en la ejecución de proyectos
  de ingeniería y exploración geofísica
  sustentada por el trabajo responsable
  y dedicado de sus directivos
  y colaboradores.
    </p>
  </div>
</div>
